
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_subsidy.distribution') }}</h4>
            </template>
            <template v-slot:body>
                <b-overlay :show="loading">
                  <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="division_id"
                      >
                      <template v-slot:label>
                        {{$t('globalTrans.division')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.division_id"
                          :options="divisionList"
                          id="division_id"
                          :disabled="authLocation.division_id != 0"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="district_id"
                      >
                      <template v-slot:label>
                        {{$t('globalTrans.district')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.district_id"
                          :options="districtList"
                          id="district_id"
                          :disabled="authLocation.district_id != 0"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="upazilla_id"
                      >
                      <template v-slot:label>
                        {{$t('org_pro_upazilla.upazilla')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.upazilla_id"
                          :options="upazillaList"
                          id="upazilla_id"
                          :disabled="authLocation.upazilla_id != 0"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="fiscal_year_id"
                        >
                        <template v-slot:label>
                          {{$t('dae_subsidy.fiscal_year')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.fiscal_year_id"
                            :options="fiscalYearList"
                            id="fiscal_year_id"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="circular_type"
                        >
                        <template v-slot:label>
                          {{$t('dae_subsidy.circular_ty')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_type"
                            :options="circularTypeList"
                            id="circular_type"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="circular_id"
                        >
                        <template v-slot:label>
                          {{$t('dae_subsidy.circular_name')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_id"
                            :options="circularList"
                            id="circular_id"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="start_date"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.from_date')}}
                          </template>
                          <flat-pickr class="form-control"
                            v-model="search.start_date"
                            :placeholder="$t('globalTrans.select_date')"
                          ></flat-pickr>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="end_date"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.to_date')}}
                        </template>
                        <flat-pickr class="form-control"
                          v-model="search.end_date"
                          :placeholder="$t('globalTrans.select_date')"
                        ></flat-pickr>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" class="text-right">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
              </b-overlay>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('dae_subsidy.distribution_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                            {{ $t('globalTrans.add_new') }}
                        </b-button> -->
<!--                        <router-link variant="success" to="/incentive-grant-service/dae/subsidy/distribution-form">-->
<!--                           <b-button variant="primary">-->
<!--                              {{ $t('globalTrans.add_new') }}-->
<!--                          </b-button>-->
<!--                        </router-link>-->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(nid_no)="data">
                                          {{ $n(data.item.nid_no, {useGrouping: false}) }}
                                        </template>
                                        <template v-slot:cell(mobile_no)="data">
                                          {{ ($i18n.locale == 'bn' ? '০': '0') + $n(data.item.mobile_no, {useGrouping: false}) }}
                                        </template>
                                        <template v-slot:cell(items_name)="data">
                                          {{ getInstrumentCropMeterial(data.item) }}
                                        </template>
                                        <template v-slot:cell(type)="data">
                                          {{ getDistributionType(data.item.type) }}
                                        </template>
                                        <template v-slot:cell(circular_type)="data">
                                          {{ getCircularType(data.item.circular_type) }}
                                        </template>
                                        <template v-slot:cell(distribution_date)="data">
                                          {{ data.item.distribution_date | dateFormat }}
                                        </template>
                                        <template v-slot:cell(quantity)="data">
                                          {{ $n(data.item.quantity) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                          <a href="javascript:" class="btn_table_action table_action_status" v-if="data.item.circular_type !== 1 && data.item.payroll.length === 0" v-b-modal.modal-payroll @click="details(data.item)" title="Prepare Payroll"><i class="fas fa-credit-card"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-payroll" size="xl" :title="$t('dae_subsidy.preparePayroll')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Payroll :item="item" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="$t('dae_subsidy.distbution_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :itemId="item" :key="item"></Details>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Payroll from './Payroll'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { distributionList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Payroll, Details
  },
  data () {
    return {
      search: {
        circular_type: 0,
        type: 0,
        circular_id: 0,
        fiscal_year_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0
      },
      rows: [],
      item: '',
      circularList: [],
      districtList: [],
      upazillaList: [],
      editItemId: 0,
      authLocation: {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0
      }
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    fiscalYearList () {
      const list = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return list.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    divisionList () {
      let divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      if (this.authLocation.division_id) {
        divisionList = divisionList.filter(item => item.value === this.authLocation.division_id)
      }
      return divisionList
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('dae_subsidy.distribution') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_subsidy.distribution') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('dae_subsidy.fiscal_year'), class: 'text-center' },
            { label: this.$t('dae_subsidy.circular_type'), class: 'text-center' },
            { label: this.$t('dae_subsidy.circular'), class: 'text-center' },
            { label: this.$t('globalTrans.division'), class: 'text-center' },
            { label: this.$t('globalTrans.district'), class: 'text-center' },
            { label: this.$t('globalTrans.upazila'), class: 'text-center' },
            { label: this.$t('dae_subsidy.distribution_data'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
                { key: 'index' },
                { key: 'fiscal_year_name_bn' },
                { key: 'circular_type' },
                { key: 'circular_name_bn' },
                { key: 'division_bn' },
                { key: 'district_bn' },
                { key: 'upazilla_bn' },
                { key: 'distribution_date' },
                { key: 'action' }
            ]
        } else {
            keys = [
                { key: 'index' },
                { key: 'fiscal_year_name' },
                { key: 'circular_type' },
                { key: 'circular_name' },
                { key: 'division' },
                { key: 'district' },
                { key: 'upazilla' },
                { key: 'distribution_date' },
                { key: 'action' }
            ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    circularTypeList () {
      const data = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Subsidy' : 'ভর্তুকি' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Incentive' : 'প্রণোদনা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Rehabilitation' : 'পুনর্বাসন' }
      ]
      return data
     },
     typeList () {
          const data = [
              { value: 1, text: this.$i18n.locale === 'en' ? 'Instrument' : 'যন্ত্র' },
              { value: 2, text: this.$i18n.locale === 'en' ? 'Crop' : 'শস্য' },
              { value: 3, text: this.$i18n.locale === 'en' ? 'Material' : 'উপাদান' }
          ]
          return data
      }
    },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.circular_type': function (newValue) {
      this.circularList = this.getCircularName(newValue)
    },
    'search.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
        this.upazillaList = this.getUpazilaList(newVal)
    }
  },
  created () {
    this.loadData()
    this.setAuthLocationInfo()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.item = item
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(incentiveGrantServiceBaseUrl, distributionList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const fiscalYear = this.$store.state.commonObj.fiscalYearList
      const listData = data.map(item => {
        const circularObj = this.$store.state.incentiveGrant.commonObj.circularList.find(circular => circular.value === item.circular_id)
        const divisionObject = this.divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazillaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
        const fiscalYearObject = fiscalYear.find(fiscal => fiscal.value === item.fiscal_year_id)
        const circularData = {
          circular_name: circularObj !== undefined ? circularObj.text_en : '',
          circular_name_bn: circularObj !== undefined ? circularObj.text_bn : ''
        }
        const fiscalYearData = {
          fiscal_year_name: fiscalYearObject !== undefined ? fiscalYearObject.text_en : '',
          fiscal_year_name_bn: fiscalYearObject !== undefined ? fiscalYearObject.text_bn : ''
        }
        const addressData = {
          division: divisionObject !== undefined ? divisionObject.text_en : '',
          division_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
        }
        const districtData = {
          district: districtObject !== undefined ? districtObject.text_en : '',
          district_bn: districtObject !== undefined ? districtObject.text_bn : ''
        }
        const upazillaData = {
          upazilla: upazillaObject !== undefined ? upazillaObject.text_en : '',
          upazilla_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ''
        }

        return Object.assign({}, item, circularData, addressData, districtData, upazillaData, fiscalYearData)
      })
      return listData
    },
    getCircularType (circularType) {
      if (circularType === 1) {
        return this.$i18n.locale === 'en' ? 'Subsidy' : 'ভর্তুকি'
      } else if (circularType === 2) {
        return this.$i18n.locale === 'en' ? 'Incentive' : 'প্রণোদনা'
      } else if (circularType === 3) {
        return this.$i18n.locale === 'en' ? 'Rehabilitation' : 'পুনর্বাসন'
      }
    },
    getDistributionType (type) {
      if (type === 1) {
        return this.$i18n.locale === 'en' ? 'Instrument' : 'যন্ত্র'
      } else if (type === 2) {
        return this.$i18n.locale === 'en' ? 'Crop' : 'শস্য'
      } else if (type === 3) {
        return this.$i18n.locale === 'en' ? 'Material' : 'উপাদান'
      }
    },
    getInstrumentCropMeterial (item) {
      if (item.circular_type === 1 && item.type === 1) {
        const instrumentObj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(instrument => instrument.value === item.instrument_id)
        if (this.$i18n.locale === 'bn') {
          return instrumentObj.text_bn
        } else {
          return instrumentObj.text_en
        }
      } else if (item.circular_type === 2 && item.type === 2) {
        const cropObj = this.$store.state.incentiveGrant.commonObj.cropList.find(crop => crop.value === item.crop_id)
        if (this.$i18n.locale === 'bn') {
          return cropObj.text_bn
        } else {
          return cropObj.text_en
        }
      } else if (item.circular_type === 3 && item.type === 2) {
        const cropObj = this.$store.state.incentiveGrant.commonObj.cropList.find(crop => crop.value === item.crop_id)
        if (this.$i18n.locale === 'bn') {
          return cropObj.text_bn
        } else {
          return cropObj.text_en
        }
      } else if (item.circular_type === 2 && item.type === 3) {
        const meterialObj = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(meterial => meterial.value === item.material_id)
        if (this.$i18n.locale === 'bn') {
          return meterialObj.text_bn
        } else {
          return meterialObj.text_en
        }
      } else if (item.circular_type === 3 && item.type === 3) {
        const meterialObj = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(meterial => meterial.value === item.material_id)
        if (this.$i18n.locale === 'bn') {
          return meterialObj.text_bn
        } else {
          return meterialObj.text_en
        }
      }
    },
    getCircularName (circularType) {
      const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.type === circularType)
      return circularList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text }
          }
      })
    },
    getDistrictList (divisionId = null) {
      let districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (this.authLocation.district_id) {
        districtList = districtList.filter(item => item.value === this.authLocation.district_id)
      }
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      let upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (this.authLocation.upazilla_id) {
        upazilaList = upazilaList.filter(item => item.value === this.authLocation.upazilla_id)
      }
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    setAuthLocationInfo () {
      let location = {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0
      }
      const authUser = this.$store.state.Auth.authUser
      if (!(authUser.role_id === 1 || authUser.is_org_admin)) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === authUser.office_id)
        if (typeof office !== 'undefined') {
          location = {
            division_id: office.division_id,
            district_id: office.district_id,
            upazilla_id: office.upazilla_id
          }
        }
      }
      this.search = Object.assign({}, this.search, location)
      this.authLocation = Object.assign({}, this.authLocation, location)
    }
  }
}
</script>
