<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{$t('globalTrans.division')}}</b-th>
                                        <b-td> {{ locale === 'en' ? distributionItem.division : distributionItem.division_bn }} </b-td>
                                        <b-th>{{$t('globalTrans.district')}}</b-th>
                                        <b-td> {{ locale === 'en' ? distributionItem.district : distributionItem.district_bn }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{$t('org_pro_upazilla.upazilla')}}</b-th>
                                        <b-td> {{ locale === 'en' ? distributionItem.upazilla : distributionItem.upazilla_bn }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.fiscal_year') }}</b-th>
                                        <b-td> {{ locale === 'en' ? fiscalYear.text : fiscalYear.text }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.circular_ty') }}</b-th>
                                        <b-td> {{ getCircularType(distributionItem.circular_type) }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.circular_name') }}</b-th>
                                        <b-td> {{ locale === 'en' ? distributionItem.circular_name : distributionItem.circular_name_bn }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.place') }}</b-th>
                                        <b-td> {{ locale === 'en' ? distributionItem.place : distributionItem.place_bn }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.distribution_data') }}</b-th>
                                        <b-td>{{ distributionItem.distribution_date }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                            <b-col lg="12" sm="12" v-if="distributionItem.circular_type === 1">
                                <h4>{{ $t('dae_subsidy.distApplicantlist') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.applicant') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.mobile_no') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.nid') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.items_name') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.application_date') }}</b-th>
                                        <b-th width="100px">{{ $t('dae_subsidy.quantity') }}</b-th>
                                    </b-tr>
                                    <b-tr v-for="(applicant,index) in applicantList" :key="index">
                                        <b-td>{{ $n(index+1) }}</b-td>
                                        <b-td>{{ ($i18n.locale === 'bn') ? applicant.name_bn : applicant.name }}</b-td>
                                        <b-td>{{ '0' + $n(applicant.mobile_no, { useGrouping: false }) }}</b-td>
                                        <b-td>{{ $n(applicant.nid_no, { useGrouping: false }) }}</b-td>
                                        <b-td>{{ ($i18n.locale === 'bn') ? applicant.instrument_name_bn : applicant.instrument_name }}</b-td>
                                        <b-td>{{ applicant.created_at | dateFormat}}</b-td>
                                        <b-td>1</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                            <div class="col-sm-12 col-md-12" v-if="distributionItem.circular_type !== 1">
                                <h4>{{ $t('dae_subsidy.farmer_list') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-thead>
                                        <b-tr>
                                            <b-th rowspan="3" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th rowspan="3" class="text-center">{{ $t('dae_subsidy.farmer_name') }}</b-th>
                                            <b-th rowspan="3" class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_father_name') }}</b-th>
                                            <b-th rowspan="3" class="text-center">{{ $t('dae_subsidy.nid') }}</b-th>
                                            <b-th rowspan="3" class="text-center">{{ $t('dae_subsidy.mobile_no') }}</b-th>
                                            <b-th rowspan="3" class="text-center">{{$t('common_config.account_type')}}</b-th>
                                            <b-th rowspan="3" class="text-center">{{$t('common_config.account_no')}}</b-th>
                                            <b-th rowspan="3" class="text-center">{{$t('bsri_demandDIS.bank')}}</b-th>
                                            <b-th rowspan="3" class="text-center">{{$t('bsri_demandDIS.branch')}}</b-th>
                                            <b-th rowspan="3" class="text-center">{{$t('dae_subsidy.cashAllocationTk')}}</b-th>
                                            <b-th :colspan="policyCrops.length + policyMaterials.length" class="text-center">{{ $t('dae_grant_allocation_distribution.allocation') }} {{ $t('dae_grant_allocation_distribution.quantity') }}</b-th>
                                        </b-tr>
                                        <b-tr>
                                            <b-th :colspan="policyCrops.length ? policyCrops.length : 1" class="text-center">{{ $t('dae_grant_allocation_distribution.crop_name') }}</b-th>
                                            <b-th :colspan="policyMaterials.length ? policyMaterials.length : 1" class="text-center">{{ $t('dae_grant_allocation_distribution.fertilizer') }}</b-th>
                                        </b-tr>
                                        <b-tr>
                                            <b-th v-for="(pCrop,pCropIndex) in policyCrops" :key="pCropIndex" class="text-center">
                                                {{ ($i18n.locale === 'bn') ? pCrop.crop_name_bn : pCrop.crop_name }}
                                            </b-th>
                                            <b-th v-for="(pMatr,pMatrIndex) in policyMaterials" :key="pMatrIndex" class="text-center">
                                                {{ ($i18n.locale === 'bn') ? pMatr.material_name_bn : pMatr.material_name }}
                                            </b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(detail,index) in applicant_details" :key="index">
                                            <b-td>{{ $n(index+1) }}</b-td>
                                            <b-td>{{ ($i18n.locale === 'bn') ? detail.farmer_data.name_bn : detail.farmer_data.name }}</b-td>
                                            <b-td>{{ ($i18n.locale === 'bn') ? detail.farmer_data.father_name_bn : detail.farmer_data.father_name }}</b-td>
                                            <b-td>{{ $n(detail.farmer_data.nid_no, { useGrouping: false }) }}</b-td>
                                            <b-td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(detail.farmer_data.mobile_no, { useGrouping: false } ) }}</b-td>
                                            <b-td>{{ detail.account_type_name }}</b-td>
                                            <b-td>{{ detail.farmer_data.ac_category_id == 1 ? ($i18n.locale === 'bn' ? '০' : '0') + $n(detail.farmer_data.wallet_no, { useGrouping: false }) : $n(detail.farmer_data.account_no, { useGrouping: false }) }}</b-td>
                                            <b-td>{{ detail.farmer_data.ac_category_id == 1 ? detail.ac_type_name : detail.bank_name }}</b-td>
                                            <b-td>{{ detail.branch_name ? detail.branch_name : '-' }}</b-td>
                                            <b-td class="text-center">{{ $n(allocationAmount, { useGrouping: false }) }}</b-td>
                                            <b-td v-for="(sinPCrop,sinPCropIndex) in policyCrops" :key="sinPCropIndex" class="text-center">
                                                {{ getCropNameData(sinPCrop, detail.demand_crop_approval)}} {{ getCropNameData(sinPCrop,detail.demand_crop_approval) !== '-' ? getCropUnitType(sinPCrop.unit_type) : '' }}
                                            </b-td>
                                            <b-td v-for="(sinPMaterial,sinPMaterialIndex) in policyMaterials" :key="sinPMaterialIndex" class="text-center">
                                                {{ getMeterialNameData(sinPMaterial,detail.demand_material_approval)}} {{ getMeterialNameData(sinPMaterial,detail.demand_material_approval) !== '-' ? getMaterialUnitType(sinPMaterial.unit_type) : '' }}
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                    <b-tfoot style="font-weight:bold">
                                        <b-tr>
                                            <b-td colspan="9" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                            <b-td class="text-right">{{ $n(grandTotal, { useGrouping: false }) }}</b-td>
                                            <b-td v-for="(cropItem, cropItemIndex) in totalRowItem.crops" :key="cropItemIndex+300" class="text-center">
                                                {{ cropItem !== 0 ? $n(cropItem) : '-' }} {{ cropItem !== 0 ? $t('globalTrans.kg') : '' }}
                                            </b-td>
                                            <b-td v-for="(matItem, matItemIndex) in totalRowItem.materials" :key="matItemIndex+300" class="text-center">
                                                {{ matItem !== 0 ? $n(matItem) : '-' }} {{ matItem !== 0 ? $t('globalTrans.kg') : '' }}
                                            </b-td>
                                        </b-tr>
                                    </b-tfoot>
                                </b-table-simple>
                            </div>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { getDistApplicantApi } from '../../api/routes'
export default {
    props: ['itemId'],
    data () {
        return {
            grandTotal: 0,
            allocationAmount: 0,
            applicantList: [],
            loading: false,
            distributionItem: '',
            fiscalYear: '',
            policyCrops: [],
            policyMaterials: [],
            applicant_details: [],
            accountTypeList: [
              { value: 1, text_en: 'Mobile Banking', text_bn: 'মোবাইল ব্যাংকিং' },
              { value: 2, text_en: 'Bank', text_bn: 'ব্যাংক' }
            ],
            totalRowItem: {
                crops: {},
                materials: {}
            }
        }
    },
    created () {
        this.distributionItem = this.getRelatinalData(this.itemId)
        this.distributionDetails(this.itemId)
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        distributionDetails (searchData) {
            this.loading = true
            const params = {
                circular_id: searchData.circular_id,
                division_id: searchData.division_id,
                district_id: searchData.district_id,
                upazilla_id: searchData.upazilla_id
            }
            RestApi.getData(incentiveGrantServiceBaseUrl, getDistApplicantApi, params).then(response => {
                if (response.success) {
                    if (this.itemId.circular_type === 1) {
                        this.applicantList = response.data
                    }
                    if (this.itemId.circular_type !== 1) {
                        this.policyCrops = response.existCropList
                        this.policyMaterials = response.existMaterialList
                        this.policyCrops.forEach(item => {
                            this.totalRowItem.crops[item.crop_id] = 0
                        })
                        this.policyMaterials.forEach(item => {
                            this.totalRowItem.materials[item.material_id] = 0
                        })
                        this.applicant_details = this.getDemandRelatinalData(response.data)
                        this.allocationAmount = response.allocationAmount
                        this.grandTotal = parseFloat(response.data.length * response.allocationAmount)
                    }
                }
                this.loading = false
            })
        },
        getDemandRelatinalData (data) {
            const listData = data.map((item, index) => {
                const deReqObj = {
                    far_general_info_id: item.far_general_info_id,
                    subs_demand_id: item.id,
                    land_quantity: item.land_quantity,
                    farmer_name: item.farmer_data.name,
                    farmer_name_bn: item.farmer_data.name_bn,
                    father_name: item.farmer_data.father_name,
                    father_name_bn: item.farmer_data.father_name_bn,
                    nid_no: item.farmer_data.nid_no,
                    mobile_no: item.farmer_data.mobile_no,
                    demandCollectioCropList: item.demand_collection_details,
                    crops: [],
                    material: []
                }
                this.policyCrops.map(tmp => {
                    deReqObj.crops.push({ id: tmp.crop_id, quantity: tmp.quantity })
                    this.totalRowItem.crops[tmp.crop_id] += this.getCropQnty(tmp, item.demand_crop_approval)
                })
                this.policyMaterials.map(tmpMt => {
                    deReqObj.material.push({ id: tmpMt.material_id, quantity: tmpMt.quantity })
                    this.totalRowItem.materials[tmpMt.material_id] += this.getMaterialQnty(tmpMt, item.demand_material_approval)
                })
                const accountTypeData = this.accountTypeList.find(type => type.value === item.farmer_data.ac_category_id)
                const accountGroupData = { account_type_name: accountTypeData !== undefined ? (this.locale === 'bn' ? accountTypeData.text_bn : accountTypeData.text_en) : '' }
                const acTypeName = this.$store.state.commonObj.bankObj.bankList.find(acType => acType.value === item.farmer_data.ac_type_id)
                const acTypeData = { ac_type_name: acTypeName !== undefined ? (this.locale === 'bn' ? acTypeName.text_bn : acTypeName.text_en) : '' }
                const bankObj = this.$store.state.commonObj.bankObj.bankList.find(bank => bank.value === item.farmer_data.bank_id)
                const bankData = { bank_name: bankObj !== undefined ? (this.locale === 'bn' ? bankObj.text_bn : bankObj.text_en) : '' }
                const branchObj = this.$store.state.commonObj.bankObj.branchList.find(branch => branch.value === item.farmer_data.branch_id)
                const branchData = { branch_name: branchObj !== undefined ? (this.locale === 'bn' ? branchObj.text_bn : branchObj.text_en) : '' }
                return Object.assign({}, item, deReqObj, accountGroupData, acTypeData, bankData, branchData)
            })
            return listData
        },
        getCircularType (circularType) {
            if (circularType === 1) {
                return this.$i18n.locale === 'en' ? 'Subsidy' : 'ভর্তুকি'
            } else if (circularType === 2) {
                return this.$i18n.locale === 'en' ? 'Incentive' : 'প্রণোদনা'
            } else if (circularType === 3) {
                return this.$i18n.locale === 'en' ? 'Rehabilitation' : 'পুনর্বাসন'
            }
        },
        getFiscalYear (fiscalyear) {
            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscal => fiscal.value === fiscalyear)
            if (this.$i18n.locale === 'bn') {
                return { value: fiscalYearObj.value, text: fiscalYearObj.text_bn }
            } else {
                return { value: fiscalYearObj.value, text: fiscalYearObj.text }
            }
        },
        getInstrument (item) {
            const instrumentObj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(instrument => instrument.value === item.instrument_id)
            if (this.$i18n.locale === 'bn') {
            return instrumentObj.text_bn
            } else {
            return instrumentObj.text_en
            }
        },
        getCrop (item) {
            const cropObj = this.$store.state.incentiveGrant.commonObj.cropList.find(crop => crop.value === item.crop_id)
            if (this.$i18n.locale === 'bn') {
                return cropObj.text_bn
            } else {
                return cropObj.text_en
            }
        },
        getmeterial (item) {
            const meterialObj = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(meterial => meterial.value === item.material_id)
            if (this.$i18n.locale === 'bn') {
                return meterialObj.text_bn
            } else {
                return meterialObj.text_en
            }
        },
        getRelatinalData (item) {
            const divisionList = this.$store.state.commonObj.divisionList
            const districtList = this.$store.state.commonObj.districtList
            const upazilaList = this.$store.state.commonObj.upazilaList
            const circularObj = this.$store.state.incentiveGrant.commonObj.circularList.find(circular => circular.value === item.circular_id)
            const divisionObject = divisionList.find(division => division.value === item.division_id)
            const districtObject = districtList.find(district => district.value === item.district_id)
            const upazillaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
            this.fiscalYear = this.getFiscalYear(item.fiscal_year_id)
            const circularData = {
                circular_name: circularObj !== undefined ? circularObj.text_en : '',
                circular_name_bn: circularObj !== undefined ? circularObj.text_bn : ''
            }
            const divisionData = {
                division: divisionObject !== undefined ? divisionObject.text_en : '',
                division_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
            }
            const districtData = {
                district: districtObject !== undefined ? districtObject.text_en : '',
                district_bn: districtObject !== undefined ? districtObject.text_bn : ''
            }
            const upazillaData = {
                upazilla: upazillaObject !== undefined ? upazillaObject.text_en : '',
                upazilla_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ''
            }
            return Object.assign({}, item, circularData, divisionData, districtData, upazillaData)
        },
        getCropQnty (singleCrop, detail) {
            const objectData = detail.find(item => item.crop_id === singleCrop.crop_id)
            return objectData !== undefined && objectData.quantity ? objectData.quantity : 0
        },
        getMaterialQnty (mat, detail) {
            const objectData = detail.find(item => item.material_id === mat.material_id)
            return objectData !== undefined && objectData.quantity ? objectData.quantity : 0
        },
        getCropNameData (singleCrop, detail) {
            const objectData = detail.find(item => item.crop_id === singleCrop.crop_id)
            return objectData !== undefined && objectData.quantity ? objectData.quantity : '-'
        },
        getCropUnitType (unit) {
            if (unit === 1) {
                return this.$t('dae_grant_allocation_distribution.kg')
            }
        },
        getMeterialNameData (singleMetrial, details) {
            const objectData = details.find(item => item.material_id === singleMetrial.material_id)
            return objectData !== undefined && objectData.quantity ? objectData.quantity : '-'
        },
        getMaterialUnitType (unit) {
            if (unit === 1) {
                return this.$t('dae_grant_allocation_distribution.kg')
            } else {
                return this.$t('dae_subsidy.pcs')
            }
        }
    }
}
</script>
